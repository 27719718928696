footer {
    position: fixed;
    bottom: 0%;
    width: 100%;
}

.App {
    display: flex;
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.iconText {
    margin: 0 .5rem;
}

.navbarStyle {
    padding: .5rem 1rem !important;
}

.navbarButton {
    color: #fff !important;
    padding: .5rem 1rem !important;
}

.iconText {
    margin: 0 .5rem
}

.navbarStyle {
    padding: .5rem 1rem
}

.navbarButton {
    color: #fff !important
}

.data-area-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly
}


.tableColumn {
    word-break: break-all
}

.table {
    max-height: 37rem;
}

.warningMessage {
    color: red;
}

.css-time-title-typography {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 2.66;
    letter-spacing: 0.08333em;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.991);
}

.css-time-typography {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.167;
    letter-spacing: 0em;
    transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(0, 0, 0, 0.6);
}

.css-time-separator {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 3rem;
    line-height: 1.167;
    letter-spacing: 0em;
    transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(0, 0, 0, 0.6);
    outline: 0px;
    margin: 0px 4px 0px 2px;
    cursor: default;
}

.navbarColor {
    background-color: black !important;
}

.cardContainer {
    position: relative;
}


.cardContainerChildren {
    position: absolute;
    top: 50%;
    left: 35%;

}

@media (min-width: 1400px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1600px !important;
    }
}

@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px;
    }
}

@media (min-width: 768px) {

    .container,
    .container-md,
    .container-sm {
        max-width: 720px;
    }
}

@media (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 540px;
    }
}

.tituloH2 {
    text-align: center;
}


.tituloH6 {
    margin-top: 3px;
}

.tituloH4 {
    margin-bottom: 12px;
}

.texto {
    font-weight: bold;
}