footer.sticky-footer {
  display: flex;
  /* position: absolute; */
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 20px;
  background-color: #e9ecef;

  margin-top: auto;
}

footer .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

footer .copyright {
  font-size: 0.8rem;
  /* line-height: 1; */
}

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}